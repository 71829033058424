import React from "react"
import styled from "@emotion/styled";

const FooterRoot = styled.footer`
    background-color: #f68507;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    color: #fff;

    a {
        color: #fff;
    }
`;

const Footer = () => {
    return (
        <FooterRoot>
            <span>© 2021 Maltina. All Rights Reserved.</span>
            <a href="https://www.maltina-nigeria.com/privacy-policy.pdf" target="_blank">Privacy Policy</a>
        </FooterRoot>
    )
}

export default Footer;

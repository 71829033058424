import React from "react"
import styled from '@emotion/styled'

const HamburgerButtonContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    margin: 0.75rem;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 0.5rem;
        width: 100%;
        background: #e8e3c3;
        border-radius: 0.5rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    span:nth-of-type(1) {
        top: 0;
    }

    span:nth-of-type(2), span:nth-of-type(3) {
        top: 1rem;
    }

    span:nth-of-type(4) {
        top: 2rem;
    }

    &.open span:nth-of-type(1) {
        top: 1rem;
        width: 0%;
        left: 50%;
    }

    &.open span:nth-of-type(2) {
        transform: rotate(45deg);
    }

    &.open span:nth-of-type(3) {
        transform: rotate(-45deg);
    }

    &.open span:nth-of-type(4) {
        top: 1rem;
        width: 0%;
        left: 50%;
    }
`;

const HamburgerButton = ({ onClick, open }) => {
    return (
        <HamburgerButtonContainer onClick={onClick} className={open ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </HamburgerButtonContainer>
    )
}

export default HamburgerButton;
